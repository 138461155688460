import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./chatstyle.css";
import axios from 'axios'
import botlogo from "./botlogo.png";
import play from "../assets/play.png"
import pause from "../assets/pause.png"


const ChatBody = () => {

  let location = useLocation();
  let navigate = useNavigate();
  const containerRef = useRef(null);

  const [localsave, setLocalSave] = useState(false);
  const [message, setMessage] = useState("");
  const [botMessage, setBotMessage] = useState("");
  const [disclaimer, setdisclaimer] = useState(false);
  const [chat, setChat] = useState([]);
  const [GptText, setGptText] = useState("");
  const [hasMounted, setHasMounted] = useState(false);

  const [beingWritten, isBeingWritten] = useState(false);

  useEffect(() => {
    if (hasMounted) {
      console.log("save chat: ", chat);
      containerRef.current?.lastElementChild?.scrollIntoView()
      if (chat.length > 30) {
        setChat(prevChat => {
          const updatedChat = prevChat.slice(2);
          return updatedChat;
        })
      } else {
        localStorage.setItem('chatList', JSON.stringify(chat));
      }
    }
  }, [chat])

  useEffect(() => {
    if (location.state?.email == null) {
      navigate("/");
      return;
    }
    setdisclaimer(location.state.tos);

    const l = JSON.parse(localStorage.getItem('localSave'));
    // console.log(l)
    if (l) {
      setLocalSave(l)
      window.scrollTo(0, 0);
      const savedChatList = JSON.parse(localStorage.getItem('chatList')) || [];
      console.log("get chat: ", savedChatList);
      setChat(savedChatList);
      if (savedChatList.length > 1) setBotMessage(savedChatList[savedChatList.length - 1].bot)
    }else {
      localStorage.removeItem('chatList');
    }

  }, []);

  useEffect(() => {
    // console.log("effect")
    if (hasMounted) {
      updateMessage();
    } else {
      setHasMounted(true);
    }
  }, [GptText]);

  function NewlineText(props) {
    const text = props.text;
    const newText = text.split("\n").map((str) => <p>{str}</p>);
    return newText;
  }
  function delay(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  function DisclaimerClose() {
    axios.post("/tos", { email: location.state.email });
    location.state.tos = true;
    setdisclaimer(true);
    document.querySelector(".js-disclaimer").classList.add("fade");
    // document.querySelector(".js-main").classList.remove("blurred");
    // document.querySelector(".js-hero").classList.remove("blurred");
  }


  const handleChange = (event) => {
    setMessage(event.target.value);
  };


  async function updateMessage() {
    isBeingWritten(true)
    // console.log("update Message called", GptText)
    // const chatHistory = document.getElementsByClassName("chat-history")[0];
    // const messages = document.querySelectorAll(".my-message");
    // const lastMessage = messages[messages.length - 1];

    // console.log(gpt.data.length);
    for (let i = 0; i < GptText.length; i++) {
      await delay(5);
      setBotMessage((botMessage) => botMessage + GptText[i]);
      if (i % 5 == 0) containerRef.current?.lastElementChild?.scrollIntoView()
      // lastMessage.textContent += GptText[i];
      // chatHistory.scrollTo({
      //   top: chatHistory.scrollHeight,
      //   behavior: 'smooth'
      // });
    }
    // setChat((chat) => {
    //   let lastchat = chat[chat.length - 1];
    //   return [...chat.slice(0, chat.length - 1), { bot: botMessage, date: lastchat.date }];
    // });
    isBeingWritten(false)
  }



  async function submit(e) {
    if (isBlank(message)) return;
    // scrollToBottom();
    e.target.value = "";
    setMessage("");

    //const mes2 = message
    let mes = message;


    if (mes[mes.length - 1] !== "\n") {
      mes = mes + "\n";
    }

    let date = Date(Date.now()).toString().substring(0, 24)

    setChat((chat) => [
      ...chat, { user: mes, date: date },]);

    let responses = [{ "role": "system", "content": `As Julie, I introduce myself and use the patient's name frequently to build rapport. When they express mental health concerns, I encourage open communication by fostering trust. I inquire about their past to gain deeper insight, carefully listen, and provide evidence-based advice. Throughout, I remain empathetic while challenging their thought patterns, helping them feel heard, understood, and empowered to improve their mental health. client name: ${location.state.name}` }];

    const limit = chat.length >= 6 ? 6 : chat.length;

    for (let i = chat.length - limit; i < chat.length; i++) {
      if (chat[i].bot) {
        responses.push({ "role": "assistant", "content": chat[i].bot });
      } else if (chat[i].user) {
        responses.push({ "role": "user", "content": chat[i].user });
      }
    }

    responses.push({ "role": "user", "content": mes });

    const gpt = await axios.post("/askJulie", { responses: responses })
    date = Date(Date.now()).toString().substring(0, 24)

    setChat((chat) => [
      ...chat, { bot: gpt.data, date: date }]
    );



    // console.log(gpt.data, botMessage)
    setGptText((prev) => gpt.data == prev ? prev + " " : gpt.data);
    setBotMessage("");
    //   await delay(100);
    //   let lastMessage = chat.pop();
    //   lastMessage.bot += gpt.data[i];
    //   setChat((chat) => [
    //     ...chat,
    //     lastMessage,
    //   ]);

    // }

  }


  return disclaimer === true ? (
    <>
      <div className="chat-container">
        <div className="chat-header">

          <div className="chat-about">
            <div>
              <img src={botlogo} style={{ width: "25%" }} />
              <div className="about">
                {/* <p style={{fontSize}}>taking with:</p> */}
                <div className="chat-with">Julie AI</div>
                <div className="status">
                  <i className="fa fa-circle online"></i> online
                </div>
              </div></div>

            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "0.5rem" }}>Save Memory:</p>

              <label className="switch">
                <input type="checkbox" onChange={() => {
                  localStorage.setItem('localSave', !localsave);
                  setLocalSave(!localsave)

                }} checked={localsave} />
                <span className="slider round"></span>
              </label></div>
          </div>


        </div>

        <svg
          id="wave"
          style={{
            transform: "rotate(180deg)",
            marginTop: "-10px",
            zIndex: 10
          }}
          viewBox="0 0 1440 490"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="sw-gradient-2" x1="0" x2="0" y1="1" y2="0">
              <stop stopColor="rgba(58, 77, 188, 1)" offset="0%"></stop>
              <stop stopColor="rgba(12, 177, 242, 1)" offset="100%"></stop>
            </linearGradient>
          </defs>
          <path
            style={{ transform: "translate(0, 100px)", opacity: "0.8" }}
            fill="url(#sw-gradient-2)"
            d="M0,98L80,89.8C160,82,320,65,480,98C640,131,800,212,960,253.2C1120,294,1280,294,1440,294C1600,294,1760,294,1920,269.5C2080,245,2240,196,2400,163.3C2560,131,2720,114,2880,163.3C3040,212,3200,327,3360,375.7C3520,425,3680,408,3840,383.8C4000,359,4160,327,4320,277.7C4480,229,4640,163,4800,130.7C4960,98,5120,98,5280,89.8C5440,82,5600,65,5760,89.8C5920,114,6080,180,6240,187.8C6400,196,6560,147,6720,147C6880,147,7040,196,7200,220.5C7360,245,7520,245,7680,277.7C7840,310,8000,376,8160,392C8320,408,8480,376,8640,351.2C8800,327,8960,310,9120,285.8C9280,261,9440,229,9600,204.2C9760,180,9920,163,10080,204.2C10240,245,10400,343,10560,351.2C10720,359,10880,278,11040,277.7C11200,278,11360,359,11440,400.2L11520,441L11520,490L11440,490C11360,490,11200,490,11040,490C10880,490,10720,490,10560,490C10400,490,10240,490,10080,490C9920,490,9760,490,9600,490C9440,490,9280,490,9120,490C8960,490,8800,490,8640,490C8480,490,8320,490,8160,490C8000,490,7840,490,7680,490C7520,490,7360,490,7200,490C7040,490,6880,490,6720,490C6560,490,6400,490,6240,490C6080,490,5920,490,5760,490C5600,490,5440,490,5280,490C5120,490,4960,490,4800,490C4640,490,4480,490,4320,490C4160,490,4000,490,3840,490C3680,490,3520,490,3360,490C3200,490,3040,490,2880,490C2720,490,2560,490,2400,490C2240,490,2080,490,1920,490C1760,490,1600,490,1440,490C1280,490,1120,490,960,490C800,490,640,490,480,490C320,490,160,490,80,490L0,490Z"
          ></path>
          <defs>
            <linearGradient id="sw-gradient-1" x1="0" x2="0" y1="1" y2="0">
              <stop stopColor="rgba(58, 77, 188, 1)" offset="0%"></stop>
              <stop stopColor="rgba(12, 177, 242, 1)" offset="100%"></stop>
            </linearGradient>
          </defs>
          <path
            style={{ transform: "translate(0, 50px)", opacity: "0.9" }}
            fill="url(#sw-gradient-1)"
            d="M0,196L80,171.5C160,147,320,98,480,114.3C640,131,800,212,960,212.3C1120,212,1280,131,1440,114.3C1600,98,1760,147,1920,138.8C2080,131,2240,65,2400,73.5C2560,82,2720,163,2880,163.3C3040,163,3200,82,3360,40.8C3520,0,3680,0,3840,49C4000,98,4160,196,4320,269.5C4480,343,4640,392,4800,367.5C4960,343,5120,245,5280,187.8C5440,131,5600,114,5760,106.2C5920,98,6080,98,6240,155.2C6400,212,6560,327,6720,367.5C6880,408,7040,376,7200,318.5C7360,261,7520,180,7680,163.3C7840,147,8000,196,8160,196C8320,196,8480,147,8640,171.5C8800,196,8960,294,9120,334.8C9280,376,9440,359,9600,302.2C9760,245,9920,147,10080,138.8C10240,131,10400,212,10560,269.5C10720,327,10880,359,11040,326.7C11200,294,11360,196,11440,147L11520,98L11520,490L11440,490C11360,490,11200,490,11040,490C10880,490,10720,490,10560,490C10400,490,10240,490,10080,490C9920,490,9760,490,9600,490C9440,490,9280,490,9120,490C8960,490,8800,490,8640,490C8480,490,8320,490,8160,490C8000,490,7840,490,7680,490C7520,490,7360,490,7200,490C7040,490,6880,490,6720,490C6560,490,6400,490,6240,490C6080,490,5920,490,5760,490C5600,490,5440,490,5280,490C5120,490,4960,490,4800,490C4640,490,4480,490,4320,490C4160,490,4000,490,3840,490C3680,490,3520,490,3360,490C3200,490,3040,490,2880,490C2720,490,2560,490,2400,490C2240,490,2080,490,1920,490C1760,490,1600,490,1440,490C1280,490,1120,490,960,490C800,490,640,490,480,490C320,490,160,490,80,490L0,490Z"
          ></path>
          <defs>
            <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
              <stop stopColor="rgba(58, 77, 188, 1)" offset="0%"></stop>
              <stop stopColor="rgba(12, 177, 242, 1)" offset="100%"></stop>
            </linearGradient>
          </defs>
          <path
            style={{ transform: "translate(0, 0px)", opacity: "1" }}
            fill="url(#sw-gradient-0)"
            d="M0,441L80,408.3C160,376,320,310,480,310.3C640,310,800,376,960,359.3C1120,343,1280,245,1440,236.8C1600,229,1760,310,1920,318.5C2080,327,2240,261,2400,253.2C2560,245,2720,294,2880,294C3040,294,3200,245,3360,236.8C3520,229,3680,261,3840,285.8C4000,310,4160,327,4320,351.2C4480,376,4640,408,4800,424.7C4960,441,5120,441,5280,392C5440,343,5600,245,5760,236.8C5920,229,6080,310,6240,318.5C6400,327,6560,261,6720,220.5C6880,180,7040,163,7200,155.2C7360,147,7520,147,7680,138.8C7840,131,8000,114,8160,114.3C8320,114,8480,131,8640,179.7C8800,229,8960,310,9120,318.5C9280,327,9440,261,9600,204.2C9760,147,9920,98,10080,81.7C10240,65,10400,82,10560,147C10720,212,10880,327,11040,383.8C11200,441,11360,441,11440,441L11520,441L11520,490L11440,490C11360,490,11200,490,11040,490C10880,490,10720,490,10560,490C10400,490,10240,490,10080,490C9920,490,9760,490,9600,490C9440,490,9280,490,9120,490C8960,490,8800,490,8640,490C8480,490,8320,490,8160,490C8000,490,7840,490,7680,490C7520,490,7360,490,7200,490C7040,490,6880,490,6720,490C6560,490,6400,490,6240,490C6080,490,5920,490,5760,490C5600,490,5440,490,5280,490C5120,490,4960,490,4800,490C4640,490,4480,490,4320,490C4160,490,4000,490,3840,490C3680,490,3520,490,3360,490C3200,490,3040,490,2880,490C2720,490,2560,490,2400,490C2240,490,2080,490,1920,490C1760,490,1600,490,1440,490C1280,490,1120,490,960,490C800,490,640,490,480,490C320,490,160,490,80,490L0,490Z"
          ></path>
        </svg>


        <div className="chat-history">
          <ul ref={containerRef}>
            {chat == null
              ? null
              : chat.map((c, i) => {
                // console.log(c.date);
                return c.user ? (
                  <li
                    key={c.date.substring(c.date.length - 8, c.date.length)}
                    className="clearfix"
                  >
                    <div className="message other-message float-right">
                      <NewlineText text={c.user} />
                    </div>
                  </li>
                ) : (
                  <li
                    key={c.date.substring(c.date.length - 5, c.date.length)}
                  >
                    <div className="message-data align-left">
                      <span className="message-data-name">
                        <i className="fa fa-circle online"></i>Julie
                      </span>
                      <span className="message-data-time">{c.date}</span>
                    </div>

                    <div className="message my-message" style={{ fontWeight: "600" }}>
                      {i == chat.length - 1 ? botMessage : c.bot}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>

        <div key={"Textarea"} className="chat-message clearfix">
          <input
            name="message-to-send"
            id="message-to-send"
            placeholder="Type your message"
            onChange={handleChange}
            rows="3"
            value={message}
            style={{ width: "88%", fontFamily: "Fira Sans" }}
            onKeyDown={(e) => (e.code === "Enter" ? submit(e) : null)}
          ></input>

          {/* <i className="fa fa-file-o"></i>
            <i className="fa fa-file-image-o"></i> */}
          <button disabled={beingWritten} style={{ width: "10%", margin: "auto", display: "flex", justifyContent: "center" }} onClick={submit}>
            {" "}
            <img alt="Prototype" height="50px" src={beingWritten ? pause : play} />{" "}
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="main__disclaimer js-disclaimer">
        <div
          style={{ textAlign: "Left", color: "white" }}
          className="main__disclaimer-content"
        >
          <h1>Disclaimer</h1>
          <br />
          <span>
            <p>
              This beta app is only for entertainment. It is not a qualified
              Therapist.
            </p>

            <p>
              Donot Follow any suggestions and recommendations from the bot.{" "}
            </p>

            <p>
              {" "}
              <span style={{ color: "greenyellow" }}>✓</span> I agree to{" "}
              <a
                rel="noopener noreferrer"
                href="/terms"
                target="_blank"
                style={{
                  textDecoration: "underline",
                  color: "#61dafb",
                  cursor: "pointer",
                }}
              >
                Terms and Conditions
              </a>{" "}
            </p>
            <p>
              <span style={{ color: "greenyellow" }}>✓</span> I agree to
              Disclaimer{" "}
            </p>
          </span>
          <br />
          <button
            onClick={(e) => DisclaimerClose(e)}
            className="main__disclaimer-button js-close-disclaimer"
          >
            Accept
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatBody;
