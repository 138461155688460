// Signup.js
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [CapatchaResult, setCapatchaResult] = useState('Robot');

  const navigate = useNavigate();
  const captchaRef = useRef(null);

  function handleSignup(e) {
    e.preventDefault();

    if (name === '' || email === '' || password === '') {
      alert("Please Fill in the fields");
    } else if (CapatchaResult === 'Human') {
      axios.post("/signup", { name, email, password })
        .then((res) => {
          console.log(res);
          navigate("/signin");
        })
        .catch(e => {
          if (e.response.status === 409) {
            alert("User Already Exists");
          }
        });
    }
  }

  async function CaptchaVerify(e) {
    const token = captchaRef.current.getValue();
    const res = await axios.post("/captcha-verify", { token: token || e });
    setCapatchaResult(res.data);
  }

  return (
    <div className="Auth-form-container">
      <form onSubmit={handleSignup} className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign Up</h3>
          <div style={{ "color": "black" }} className="text-center">
            Already Registered?{" "}
            <span className="link-primary" onClick={() => {navigate("/signin")}}>
              Sign In
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Full Name</label>
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group mt-3">
            <ReCAPTCHA
              onChange={CaptchaVerify}
              ref={captchaRef}
              sitekey="6LfemZYkAAAAAOUY_DtfKb1GUk5G54UNrC7tEbsG"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Signup;
