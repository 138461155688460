import React from "react";
import "./terms.scss";
const Terms = () => {
  return (
    <div>
      <main class="wrap">
        <section class="container22">
          <div class="container22__heading">
            <h2>Terms & Conditions</h2>
          </div>
          <div class="container22__content">
            <p>
              These Terms and Conditions (“Agreement”) govern the use of
              OKTalk AI, an AI-based chatbot toy (“Service”) provided by Bespoke
              Therapy (“Provider” or “we”).
            </p>

            <br />

            <h3>Acceptance of Terms</h3>

            <br />

            <p>
              By accessing and using the Service, you agree to be bound by this
              Agreement. If you do not agree to these terms, please do not use
              the Service. Please note that this service includes the use of an
              AI language model (the "Bot") that is intended to provide
              responses for entertainment only. The Bot's responses should not
              be considered professional or personal advice, and the accuracy or
              reliability of the responses cannot be guaranteed. You agree not
              to rely solely on the Bot's responses and to use your own judgment
              and discretion when interpreting and applying or using the
              information provided. Additionally, you agree not to take the
              Bot's responses too seriously and to understand that the Bot may
              not always behave as intended. We provide no warranty whatsoever
              as to the Bot's performance or reliability. If you have any
              concerns or issues related to the Bot's performance, please
              contact us.
            </p>

            <br />

            <h3>Use of Service</h3>

            <br />

            <p>
              The Service is an AI-based chatbot Toy that provides automated
              responses to user inquiries. The Service is provided for
              informational and entertainment purposes only and is not intended
              to replace human interaction. You agree not to use the Service for
              any illegal, harmful, or offensive purpose, or in any way that
              violates the rights of others. Additionally, you agree that the
              Service is provided "as is," and we make no representations or
              warranties of any kind, express or implied, regarding the
              accuracy, reliability, or completeness of the Service. We disclaim
              all liability for any damages or losses arising from your use of
              the Service or reliance on the information provided by the Bot.
              You acknowledge and agree that your use of the Service is entirely
              at your own risk. If you have any concerns or issues related to
              the Service, please contact us.
            </p>

            <br />

            <h3>User Conduct</h3>

            <p>
              You agree to use the Service only for lawful purposes and in a
              manner consistent with all applicable laws, regulations, and codes
              of conduct. You further agree not to use the Service to:
              <br />
              <br /> <b>a</b>. Transmit any content that is unlawful, harmful,
              threatening, abusive, harassing, defamatory, vulgar, obscene,
              libelous, invasive of another’s privacy, hateful, or racially,
              ethnically, or otherwise objectionable;
              <br />
              <b>b</b>. Impersonate any person or entity, or falsely state or
              otherwise misrepresent your affiliation with a person or entity;
              <br />
              <b>c</b>. Forge headers or otherwise manipulate identifiers to
              disguise the origin of any content transmitted through the Service
              <br />
              <b>d</b>. Interfere with or disrupt the Service or servers or
              networks connected to the Service, or disobey any requirements,
              procedures, policies, or regulations of networks connected to the
              Service.
            </p>

            <br />

            <h3>Intellectual Property</h3>

            <br />

            <p>
              The Service and all materials and content contained in the
              Service, including but not limited to text, graphics, logos,
              images, and software, are protected by copyright, trademark, and
              other intellectual property laws. You acknowledge and agree that
              all ownership rights to the Service and its contents, including
              all intellectual property rights, belong to us or our licensors.
              <br /> <br /> You agree not to modify, copy, distribute, transmit,
              display, perform, reproduce, publish, license, create derivative
              works from, transfer, or sell any information, software, products,
              or services obtained from the Service. You further agree not to
              use any of the trademarks, logos, or service marks displayed on
              the Service without our prior written consent.
              <br /> <br />
              You acknowledge and agree that any unauthorized use of the Service
              or its contents may result in irreparable harm to us and that we
              may seek injunctive or other appropriate relief in any court of
              competent jurisdiction.
            </p>

            <br />

            <h3>Disclaimer of Warranties</h3>

            <br />

            <p>
              The Service is provided on an "as is" and "as available" basis. We
              make no warranties or representations, whether express or implied,
              including but not limited to warranties of merchantability,
              fitness for a particular purpose, or non-infringement. We do not
              warrant that the Service will be uninterrupted, error-free, or
              free of viruses or other harmful components. We do not guarantee
              the accuracy, timeliness, reliability, completeness, or usefulness
              of any information obtained through the Service. You acknowledge
              and agree that your use of the Service is at your own risk. We do
              not assume any liability or responsibility for any errors,
              omissions, or inaccuracies in the Service or any content or
              materials obtained through the Service. We also do not assume any
              liability or responsibility for any damages, whether direct,
              indirect, incidental, special, or consequential, arising out of or
              in connection with your use of the Service. We reserve the right
              to modify or discontinue the Service at any time without notice.
              We also reserve the right to limit or restrict access to the
              Service or any portion of the Service at any time without notice.
              If you have any concerns or issues related to the Disclaimer of
              Warranties, please contact us.
            </p>

            <br />

            <h3>Limitation of Liability</h3>

            <br />

            <p>
              In no event shall Provider be liable for any damages, including,
              without limitation, direct, indirect, incidental, special,
              consequential, or punitive damages arising out of the use of or
              inability to use the Service, even if Provider has been advised of
              the possibility of such damages. By using the Service, you
              acknowledge and agree that the Provider is not responsible for any
              advice, diagnosis, or treatment provided by the Service. The
              Service is not intended to be a substitute for professional
              advice, diagnosis, or treatment from a qualified healthcare
              provider.
            </p>
            <br />
            <h3>Indemnification</h3>
            <br />
            <p>
              You agree to indemnify, defend, and hold harmless Provider, its
              officers, directors, employees, agents, licensors, and suppliers
              from and against all claims, losses, expenses, damages, and costs,
              including reasonable attorneys’ fees, resulting from any violation
              of this Agreement.
            </p>
            <br />
            <h3>Termination</h3>
            <br />
            <p>
              The provider may terminate or suspend your access to the Service
              at any time, with or without notice, for any reason.
            </p>
            <br />
            <h3>Data Collection Policy:</h3>
            <br />
            <p>
              We do not collect any personal data from users of the Service. We
              do not use cookies or any other tracking mechanisms to collect
              information about your use of the Service. Your privacy is
              important to us, and we do not share any personal information with
              third parties.
              <br /> <br />
              The chat logs of your conversations with the Service are stored
              temporarily for the purpose of providing you with the chat history
              during your current session. However, we do not store or use this
              information for any other purpose. You can delete the chat history
              at any time by clearing your browser cache or by clicking the
              "Delete Chat" button at the end of the chat session.
              <br /> <br />
              We reserve the right to modify this Data Collection Policy at any
              time. Any changes will be posted on this page, and your continued
              use of the Service following the posting of any changes
              constitutes your acceptance of such changes.
            </p>
            <br />
            <h3>Privacy Policy</h3>
            <br />
            <p>
              At OKTalk AI, we are committed to protecting your privacy. This
              Privacy Policy describes how we collect, use, and disclose
              information about you when you use the Service. By using the
              Service, you consent to the collection, use, and disclosure of
              information as described in this Privacy Policy.
            </p>
            <br />
            <h3>Governing Law and Jurisdiction</h3>
            <br />
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of England and Wales, without giving effect to any
              principles of conflicts of law. Any dispute or claim arising out
              of or in connection with this Agreement, or the breach,
              termination, or invalidity thereof, shall be submitted to the
            </p>
            <br />
          </div>
          <div class="container__nav">
            <small>
              By clicking 'Accept' you are agreeing to our terms and conditions.
            </small>
            <a class="button" href="#">
              Accept
            </a>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Terms;
