import React from "react";
import baby from "./baby.jpg";
import { Link } from "react-router-dom";
import julie from "../assets/julie.jpeg";
import ziggy from "../assets/ziggy.jpeg";
import joe from "../assets/joe.jpeg";
import Navv from "./Navbar";

const Home = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <Navv />
      {/* Main */}
      <main className="main">
        {/* Hero */}
        <section className="hero">
          <div className="hero__container container container--px flex">
            <div className="hero__text">
              <h1 className="hero__title">
                Welcome to{" "}
                <span className="hero__title-underline">OKTalk.AI</span>
              </h1>
              <br />
              <p className="hero__paragraph">
                OKTalk AI is a revolutionary web-based AI therapy chatbot that
                provides users with a safe and confidential space to receive
                support and guidance. It is designed to help individuals who may
                be struggling with various issues such as anxiety, depression,
                stress, and many more.
                {/* Therabot AI is a revolutionary web-based therapy chatbot that provides users with a safe and confidential space to receive mental health support and guidance. It is designed to help individuals who may be struggling with various mental health issues such as anxiety, depression, stress, and more. */}
              </p>
              <br />
              <Link
                to="/about"
                id="readmore"
                style={{ marginRight: "10px", marginTop: "0" }}
                className="hero__cta cta cta--primary"
              >
                Read more ➚
              </Link>

              <Link to="/signup" className="hero__cta cta cta--primary">
                Talk to Julie ➚
              </Link>
            </div>
            <div className="hero__image-wrapper">
              <img
                src={baby}
                alt="Hand Holding Joystick"
                className="hero__image"
              />
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  color: "#3a3a3a",
                  width: "72%",
                }}
              >
                As you can see this is a rough prototype interface, please over look that it is still a little rough. With continued work it will be 100x better, your time is appreciated by our whole team
              </p>
            </div>
          </div>
        </section>
        {/* Partbers */}
        <div style={{ width: "900px", display: "flex", flexFlow: "column" }}>
          <img
            src={julie}
            alt="team member avatar"
            className="julie"
            loading="lazy"
            
          />
          <div className="member__info">
            <span className="member__title">Julie AI</span>
            <span style={{ display: "block", marginBottom: "10px", color: "green" }} > Avaliable</span>
            <span className="member__designation">
              Julie AI is the perfect blend of some of the best and most
              compassionate psychotherapists and counsellors that we could
              find worldwide. With a combined of experience of lifetimes,
              in providing support to those in need, Julie AI brings a
              level of professionalism and expertise that is unparalleled.
              She is dedicated to helping you overcome your challenges and
              will work tirelessly to ensure that you receive the care you
              deserve. Trust us, even at $1000 an hour, you won't find
              anyone who will listen to you at 4am like she will.
            </span>
          </div>
        </div>
        <section className="team">
          <div className="team__container container container--px">
            <div className="team__text">
              {/* <h1 className="subtitle-primary">Therapists</h1> */}
              <h4 className="title-primary">
                2 new therapists will be starting shortly:
              </h4>
            </div>
            <div className="team__wrapper">


              <div className="team__member member member--3">
                <img
                  src={ziggy}
                  alt="team member avatar"
                  className="member__image"
                  loading="lazy"
                />
                <div className="member__info">

                  <span className="member__title">Ziggy AI</span>
                  <span style={{ display: "block", marginBottom: "10px" }} >(Coming soon)</span>
                  <span className="member__designation">
                    Ziggy AI is a unique and unconventional therapist who is
                    perfect for those looking for a more lighthearted and
                    playful approach to therapy. His sharp wit and surreal humor
                    may not be for everyone, but for those who connect with his
                    style, Ziggy AI can be an incredibly effective therapist. He
                    has a knack for getting to the root of things quickly and
                    helping you see things in a new light. If you're looking for
                    a therapist who will make you laugh and challenge you to
                    think differently, Ziggy AI is the perfect choice. However,
                    please note that his style may not be suitable for those who
                    are highly sensitive.
                  </span>
                </div>
              </div>

              <div className="team__member member member--2">
                <img
                  src={joe}
                  alt="team member avatar"
                  className="member__image"
                  loading="lazy"
                />
                <div className="member__info">

                  <span className="member__title">Joe AI</span>
                  <span style={{ display: "block", marginBottom: "10px" }} >(Coming soon)</span>
                  <span className="member__designation">
                    Joe AI is like having a warm and supportive friend who is
                    always there to listen and offer guidance. With a kind and
                    caring demeanor, Joe AI creates a safe space where you can
                    share your thoughts and feelings without fear of judgment.
                    He has a wealth of life experience and is unshockable,
                    nothing you say will ever surprise him. Joe AI is always
                    ready to offer insight and wisdom to help you navigate your
                    challenges. Whether you're struggling with anxiety, fear, or
                    something you could not talk to a living soul about, Joe AI
                    is here to support you every step of the way. He forgets on
                    command and will not repeat what you say to a living soul.{" "}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="team__footer">
              <Link to="/" className="team__link cta cta--link">
                View all
                <svg
                  className="testimonial__link__icon cta--link-icon"
                  viewBox="0 0 17 14"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    d="M1 7.375L16 7.375M16 7.375L10.375 1.75M16 7.375L10.375 13"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div> */}
          </div>
        </section>
      </main>
      {/* Footer (: */}
      <footer className="footer">
        <div className="footer__container container container--px flex">
          <div className="footer__header flex">
            <Link to="/" className="footer__brand" title="Back to the homepage">
              OKTalk AI
            </Link>
            <div className="footer__social-icons social-icons flex">
              <Link
                to="/"
                className="social-icons__link social-icons__link--dribbble"
              >
                <i className="bx bxl-dribbble" />
                <span className="visually-hidden">Dribbble</span>
              </Link>
              <Link
                to="/"
                className="social-icons__link social-icons__link--facebook"
              >
                <i className="bx bxl-facebook" />
                <span className="visually-hidden">Facebook</span>
              </Link>
              <Link
                to="/"
                className="social-icons__link social-icons__link--twitter"
              >
                <i className="bx bxl-twitter" />
                <span className="visually-hidden">Twitter</span>
              </Link>
              <Link
                to="/"
                className="social-icons__link social-icons__link--youtube"
              >
                <i className="bx bxl-youtube" />
                <span className="visually-hidden">YouTube</span>
              </Link>
            </div>
          </div>
          <nav className="footer__inner flex">
            <ul className="footer__list list" role="menu">
              <h3 className="list__title">Home</h3>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Partners
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Features
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Testimonial
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Team
                </Link>
              </li>
            </ul>
            <ul className="footer__list list" role="menu">
              <h3 className="list__title">Pages</h3>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  About Us
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Careers
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Case Studies
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Pricing
                </Link>
              </li>
            </ul>
            <ul className="footer__list list" role="menu">
              <h3 className="list__title">Blog</h3>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Blog Listing
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Blog Article
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Newsroom
                </Link>
              </li>
            </ul>
            <ul className="footer__list list" role="menu">
              <h3 className="list__title">Portfolio</h3>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Portfolio
                </Link>
              </li>
              <li className="list__item" role="none">
                <Link to="/" className="list__link" role="menuitem">
                  Single Case
                </Link>
              </li>
            </ul>
          </nav>
          <p className="footer__text">© 2023 OKTalk AI</p>
        </div>
      </footer>
    </>
  );
};

export default Home;
