import React from 'react'

const About = () => {
  return (

    < div style={{width: "80%",margin: "auto"}} >

      <h1><u>About</u> </h1>

      <p>
        Julie is a revolutionary web-based therapy chatbot that provides users with a safe and confidential space to receive mental health support and guidance. It is designed to help individuals who may be struggling with various mental health issues such as anxiety, depression, stress, and more.
      </p><p>
        Our goal is to create a revolutionary chat therapist that will help people improve their mental health and wellbeing.
      </p><p>
        The current mental health crisis affects people all around the world, and while there are many ways to address it, many people are not able to access traditional therapy for various reasons. That's why we have created  AI, a chatbot that provides emotional support and guidance to people struggling with their mental health 24/7.
      </p><p>
         AI uses the latest AI and NLP technology to understand and respond to your thoughts and emotions, creating a safe and supportive environment where you can express your feelings and concerns. It's like having a caring and compassionate friend who is always there for you. But we need your help to make  AI even better. We are raising funds to improve our technology and create ever more advanced versions of  AI that can offer even more personalized and effective support.
      </p>

    </div >
  )
}

export default About