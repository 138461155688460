import { Link } from "react-router-dom";

function Navv() {
    return (
        <>
            <header id="header" className="header">
                <div className="header__container container container--px">
                    <nav className="navbar flex">
                        <Link to="/" className="navbar__brand" title="Back to the homepage">
                            OKTalk AI
                        </Link>


                    </nav>
                    <div id="overlayLayer" className="header__overlay" />
                </div>
            </header>
        </>
    );
}

export default Navv;